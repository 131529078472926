export const POST_LOGIN = '/auth/login';

export const UPLOAD_FILE = '/upload';

// Users
export const GET_USERS = '/users';
export const SEARCH_USERS = '/users/search';
export const CHANGE_PASSWORD = '/user/change-password';
export const GET_USER_JOB_HISTORY = '/jobs/userJobsHistory';

// Quotes
export const CREATE_QUOTE = '/quotes';
export const GET_QUOTES = '/quotes';
export const GET_QUOTE_BY_ID = '/quotes';
export const CHANGE_QUOTE_STATUS = '/quotes/actions';
export const UPDATE_QUOTE = '/quotes';
export const UPDATE_EVENT_NOTES = '/quotes/notes';
export const DELETE_QUOTE = '/quotes';

// export const CHANGE_PASSWORD = '/user/change-password';

// Shifts
export const GET_EVENTS = '/quotes/approved';
export const GET_SHIFTS = '/shifts/event';
export const ASSIGN_SHIFTS = '/shifts/assign';
export const DELETE_SHIFT = '/shifts';
export const CREATE_LEAD = '/shifts/lead';

// Bids
export const GET_BIDS = '/bids/shift';
export const DELETE_BID = '/bids';

export const GET_JOBS = '/jobs/event';
export const UPDATE_JOB = '/jobs';
export const UPDATE_JOB_NOTES = '/jobs/notes';
export const DELETE_JOB = '/jobs';

export const CREATE_INVOICE = '/attendances/createInvoice';
export const APPROVE_INVOICE = '/attendances/approveOrRequest';
export const CLINET_INVOICE_JOBS = '/jobs/clientInvoice';
export const CREATE_INVOICE_JOBS = '/jobs/clientInvoice';
export const CHANGE_INVOICE_JOBS = '/jobs/changeClientInvoiceStatus';

export const GET_REFFERALS = '/refferal/fetch';
export const GET_FILTER_REFFERALS = '/refferal/filter';

export const GET_ALL_CHATS = '/chats';
export const FIND_CHAT = '/chats/search';
export const GET_MESSAGES = '/messages/chat';
export const CREATE_MESSAGE = '/messages';

// Attendance
export const GET_ATTENDANCE = '/attendances/all';

export const SEND_NOTIFICATION = '/notifications';

export const GET_CALENDER = '/shifts/calender';

export const SEND_REMINDERS = '/jobs/reminders';
