import React, { useEffect, useState } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import router from 'src/router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { Navigate } from 'react-router-dom';
import Login from './components/Login/Login';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation(); // To get current pathname

  const content = useRoutes(router);

  useEffect(() => {
    // Check if PLS is in local storage
    const user = localStorage.getItem('PLS');

    if (user) {
      // If PLS is found, set isLoggedIn to true
      setIsLoggedIn(true);
    }
  }, []);

  const publicPaths = [
    '/',
    '/quote/quoteTemplate',
    '/quote/invoiceTemplate',
    /^\/quoteForClient\/[^/]+$/,
    /^\/invoiceForClient\/[^/]+$/
  ];

  const isPublicPath = publicPaths.some((path) =>
    typeof path === 'string'
      ? location.pathname === path
      : path.test(location.pathname)
  );

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {isLoggedIn || isPublicPath ? content : <Login />}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
