import { useState, ChangeEvent, useEffect } from 'react';
import {
  Box,
  Typography,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  TextField,
  IconButton,
  InputAdornment,
  Avatar,
  List,
  Button,
  Tooltip,
  Divider,
  AvatarGroup,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  lighten,
  styled,
  Modal,
  CircularProgress
} from '@mui/material';
import { formatDistance, subMinutes, subHours } from 'date-fns';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import Label from 'src/components/Label';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import AlarmTwoToneIcon from '@mui/icons-material/AlarmTwoTone';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { findChat, getAllChats, searchUsers } from 'src/helper/backend_helper';
import { config } from 'src/config';
import useDebounce from './useDebounce';
import { useMutation, useQuery } from '@tanstack/react-query';

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.success.lighter};
          color: ${theme.colors.success.main};
          width: ${theme.spacing(8)};
          height: ${theme.spacing(8)};
          margin-left: auto;
          margin-right: auto;
    `
);

const MeetingBox = styled(Box)(
  ({ theme }) => `
          background-color: ${lighten(theme.colors.alpha.black[10], 0.5)};
          margin: ${theme.spacing(2)} 0;
          border-radius: ${theme.general.borderRadius};
          padding: ${theme.spacing(2)};
    `
);

const RootWrapper = styled(Box)(
  ({ theme }) => `
        padding: ${theme.spacing(2.5)};
  `
);

const ListItemWrapper = styled(ListItemButton)(
  ({ theme }) => `
        &.MuiButtonBase-root {
            margin: ${theme.spacing(1)} 0;
        }
  `
);

const TabsContainerWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTabs-indicator {
            min-height: 4px;
            height: 4px;
            box-shadow: none;
            border: 0;
        }

        .MuiTab-root {
            &.MuiButtonBase-root {
                padding: 0;
                margin-right: ${theme.spacing(3)};
                font-size: ${theme.typography.pxToRem(16)};
                color: ${theme.colors.alpha.black[50]};

                .MuiTouchRipple-root {
                    display: none;
                }
            }

            &.Mui-selected:hover,
            &.Mui-selected {
                color: ${theme.colors.alpha.black[100]};
            }
        }
  `
);

function SidebarContent({ chat, setChat }) {
  const user = {
    name: 'Catherine Pike',
    avatar: '/static/images/avatars/1.jpg',
    jobtitle: 'Software Developer'
  };

  const location: any = useLocation();

  const [page, setPage] = useState(1);
  const [pageSize] = useState(20);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  // Fetch chats
  const { data: chatData, isFetching: isChatLoading } = useQuery(
    ['chats'],
    () => getAllChats({ page, pageSize }),
    {
      // keepPreviousData: true,
      staleTime: 1000 * 60 * 1
    }
  );

  // Search users
  const { data: searchResults, isFetching: isSearching } = useQuery(
    ['searchUsers', searchTerm],
    () => searchUsers(searchTerm),
    {
      enabled: !!searchTerm // only fetch when searchTerm is not empty
    }
  );

  // Fetch a specific chat when clicking on a user
  const findChatMutation = useMutation(findChat, {
    onSuccess: (data: any) => {
      setChat(data.data.data);
      handleCloseModal();
    }
  });

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSearchTerm('');
    setIsModalOpen(false);
  };

  const handleSearch = (e) => setSearchTerm(e.target.value);

  useEffect(() => {
    const getData = async () => {
      console.log(location?.state?.id);
      if (location?.state?.id) {
        const response = await handleClickUser(location?.state?.id);
      }
    };
    getData();
  }, []);

  const handleClickUser = (id) => {
    findChatMutation.mutate(id);
  };

  return (
    <RootWrapper>
      <Box display="flex" alignItems="flex-start">
        {/* Avatar and Name */}
      </Box>

      <Typography sx={{ mb: 1, mt: 2 }} variant="h3">
        Av Workforce Team
      </Typography>

      <Box mt={2}>
        <List disablePadding component="div">
          <Box
            display="flex"
            justifyContent="space-between"
            onClick={handleOpenModal}
            sx={{ cursor: 'pointer' }}
          >
            Search user
            <SearchTwoToneIcon />
          </Box>

          {isChatLoading && <CircularProgress size={15} />}

          {/* Render chat list */}
          {chatData?.data?.data.map((item) => {
            const currentUser = JSON.parse(localStorage.getItem('PLS'))?.user;
            const otherUser = item.participants.find(
              (e) => e.userId !== currentUser?.id
            );

            return (
              <ListItemWrapper
                selected={chat?.id === item.id}
                key={item.id}
                onClick={() => setChat(item)}
              >
                <ListItemAvatar>
                  <Avatar
                    src={config.FILe_URL + otherUser?.user.profileImage}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    mr: 1
                  }}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'h5',
                    noWrap: true
                  }}
                  secondaryTypographyProps={{
                    color: 'textSecondary',
                    noWrap: true
                  }}
                  primary={
                    otherUser?.user.firstName + ' ' + otherUser?.user.lastName
                  }
                  secondary={item.lastMessage}
                />
                {item.unReadCount > 0 && item.lastSenderId !== currentUser.id && (
                  <Label color="primary">
                    <b>{item.unReadCount}</b>
                  </Label>
                )}
              </ListItemWrapper>
            );
          })}

          {/* Load More Button */}
          {chatData && chatData.data.meta.totalCount > pageSize && (
            <Box display="flex" justifyContent="center">
              <Button onClick={() => setPage((prev) => prev + 1)}>
                {isChatLoading ? <CircularProgress size={20} /> : 'Load More'}
              </Button>
            </Box>
          )}
        </List>
      </Box>

      {/* Modal for Search */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            p: 4
          }}
        >
          <TextField
            fullWidth
            placeholder="Search user name"
            value={searchTerm}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              )
            }}
          />
          {isSearching && <CircularProgress size={15} />}
          {searchResults?.data?.data.map((item) => (
            <ListItemWrapper
              key={item.id}
              onClick={() => handleClickUser(item.id)}
            >
              <Avatar src={config.FILe_URL + item.profileImage} />
              <ListItemText primary={item.firstName + ' ' + item.lastName} />
            </ListItemWrapper>
          ))}
        </Box>
      </Modal>
    </RootWrapper>
  );
}

export default SidebarContent;

{
  /* <Box display="flex" pb={1} mt={4} alignItems="center">
        <Typography
          sx={{
            mr: 1
          }}
          variant="h3"
        >
          Meetings
        </Typography>
        <Label color="success">
          <b>2</b>
        </Label>
      </Box> */
}
{
  /* <MeetingBox>
        <Typography variant="h4">Daily Design Meeting</Typography>

        <Box py={3} display="flex" alignItems="flex-start">
          <AlarmTwoToneIcon />
          <Box pl={1}>
            <Typography
              variant="subtitle2"
              sx={{
                lineHeight: 1
              }}
              color="text.primary"
            >
              10:00 - 11:30
            </Typography>
            <Typography variant="subtitle1">
              {formatDistance(subMinutes(new Date(), 12), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <AvatarGroup>
            <Tooltip arrow title="View profile for Remy Sharp">
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
                component={RouterLink}
                to="#"
                alt="Remy Sharp"
                src="/static/images/avatars/1.jpg"
              />
            </Tooltip>
            <Tooltip arrow title="View profile for Travis Howard">
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
                component={RouterLink}
                to="#"
                alt="Travis Howard"
                src="/static/images/avatars/2.jpg"
              />
            </Tooltip>
            <Tooltip arrow title="View profile for Craig Vaccaro">
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
                component={RouterLink}
                to="#"
                alt="Craig Vaccaro"
                src="/static/images/avatars/3.jpg"
              />
            </Tooltip>
          </AvatarGroup>

          <Button variant="contained" size="small">
            Attend
          </Button>
        </Box>
      </MeetingBox>

      <MeetingBox>
        <Typography variant="h4">Investors Council Meeting</Typography>

        <Box py={3} display="flex" alignItems="flex-start">
          <AlarmTwoToneIcon />
          <Box pl={1}>
            <Typography
              variant="subtitle2"
              sx={{
                lineHeight: 1
              }}
              color="text.primary"
            >
              14:30 - 16:15
            </Typography>
            <Typography variant="subtitle1">
              {formatDistance(subHours(new Date(), 4), new Date(), {
                addSuffix: true
              })}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <AvatarGroup>
            <Tooltip arrow title="View profile for Travis Howard">
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
                component={RouterLink}
                to="#"
                alt="Travis Howard"
                src="/static/images/avatars/4.jpg"
              />
            </Tooltip>
            <Tooltip arrow title="View profile for Craig Vaccaro">
              <Avatar
                sx={{
                  width: 28,
                  height: 28
                }}
                component={RouterLink}
                to="#"
                alt="Craig Vaccaro"
                src="/static/images/avatars/5.jpg"
              />
            </Tooltip>
          </AvatarGroup>

          <Button variant="contained" size="small">
            Attend
          </Button>
        </Box>
      </MeetingBox> */
}
